<template>
  <div class="lesson">
    <div class="status-bar">
      <div class="status-bar__back-button">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          :to="
            lesson.lesson_type == 'group'
              ? `/groups/${lesson.group.id}`
              : '/timetable'
          "
          title="back to group"
        ></vs-button>
      </div>

      <h2 class="status-bar__title" :class="{ crossed: lesson.cancelled }">
        {{ lesson.name }}
      </h2>
      <lesson-online v-if="lesson.is_online" />
      <div class="status-bar__lesson-group" v-if="lesson.group">
        <vs-chip transparent :color="lesson.group.color">
          <vs-avatar icon="people" :color="lesson.group.color" />
          {{ lesson.group.name }}
        </vs-chip>
      </div>

      <div
        class="status-bar__lesson-group"
        v-if="lesson.lesson_type == 'tutor'"
      >
        <vs-chip transparent color="primary"> Tutoring </vs-chip>
      </div>
      <div
        class="status-bar__lesson-testing"
        v-if="lesson.lesson_type == 'testing'"
      >
        <vs-chip transparent> Testing </vs-chip>
      </div>
      <div class="status-bar__teacher" v-if="lesson.main_teacher">
        <vs-chip transparent>
          <vs-avatar
            :src="
              lesson.main_teacher.options && lesson.main_teacher.options.avatar
                ? `${proxy}/static/files/${lesson.main_teacher.options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ lesson.main_teacher.nickname }}
          <span
            v-if="
              lesson.main_teacher.date_start && !lesson.main_teacher.date_end
            "
            class="lesson-in-work"
          >
            In work
          </span>
          <span
            v-if="lesson.is_main_teacher_replaced"
            class="is-main-teacher-replaced markdown small warning"
          >
            Replacement
          </span>
        </vs-chip>
      </div>
      <div
        class="status-bar__lesson-time"
        :class="{ crossed: lesson.cancelled }"
      >
        <vs-icon icon="query_builder"></vs-icon>
        {{ $moment(lesson.lesson_time * 1000).format('DD MMMM YYYY HH:mm') }}
      </div>

      <div
        v-if="lesson.date_end"
        class="status-bar__lesson-time"
        :class="{ crossed: lesson.cancelled }"
      >
        <vs-icon icon="done" color="success"></vs-icon>
        Done: {{ $moment(lesson.date_end * 1000).format('DD.MM.YYYY HH:mm') }}
      </div>
      <div class="status-bar__room" v-if="lesson.room">
        <vs-icon :icon="lesson.room.icon"></vs-icon>
        {{ lesson.room.name }}
      </div>
      <!-- lesson manage -->
      <lesson-manage :lesson="lesson" :selfLessonTeacher="selfLessonTeacher" />
      <div class="status-bar__manage-delimiter"></div>
      <div class="status-bar__manage-buttons current_books">
        <group-current-books-view
          :current_books="lesson.group ? lesson.group.current_books : null"
        />
      </div>
    </div>

    <div class="view-content">
      <Split>
        <SplitArea :size="60">
          <vs-alert
            :active="lesson.cancelled"
            color="danger"
            icon="new_releases"
            size="small"
            v-if="lesson.cancelled"
          >
            <b>Lesson canceled </b>
            <p>{{ lesson.cancel_description }}</p>
          </vs-alert>
          <div class="lesson__sub-menu">
            <div class="lesson-tests" v-if="lesson.lesson_type == 'group'">
              <vs-dropdown
                vs-trigger-click
                v-if="tests"
                class="add-test-dropdown"
              >
                <span>
                  <vs-icon class="" icon="add"></vs-icon>
                  Add test to lesson
                  <vs-icon class="" icon="expand_more"></vs-icon>
                </span>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="test in tests"
                    :key="test.id"
                    @click="addTestHandle($event, test.id)"
                  >
                    <span class="test-completed">
                      <span v-if="test.results_count">
                        <vs-icon icon="checked"></vs-icon>
                      </span>
                    </span>
                    {{ test.name }} ({{ test.book.name }})
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-button
                icon="delete"
                type="flat"
                color="danger"
                size="small"
                @click="removeAllTestHandler"
                v-if="lesson.options && lesson.options.tests"
                >Remove all tests from lesson</vs-button
              >
            </div>
            <div
              class="surprise__add-button add-button"
              v-if="lesson.lesson_type != 'testing'"
            >
              <vs-button
                icon="star"
                type="flat"
                @click="surprisePopupActive = true"
                >Add surprise to student</vs-button
              >
            </div>
            <div class="surprise__add-button add-button" v-else>
              <vs-button
                icon="colorize"
                type="flat"
                @click="addAllIncomeTestsHandler"
                >Add tests to all students</vs-button
              >
            </div>
            <div
              class="status-bar__manage-buttons"
              v-if="$can('manage', 'lesson')"
            >
              <vs-button
                icon="edit"
                class="status-bar__edit-button"
                type="flat"
                color="dark"
                @click="popupActive = true"
                size="small"
                >Edit lesson</vs-button
              >
            </div>
            <div
              class="status-bar__manage-buttons"
              v-if="$can('manage', 'lesson') && lesson.lesson_type == 'tutor'"
            >
              <vs-button
                icon="delete"
                class="status-bar__edit-button"
                type="flat"
                color="danger"
                @click="deleteLessonHandler(event)"
                size="small"
                >Delete</vs-button
              >
            </div>
          </div>

          <LessonView
            :lesson="lesson"
            :key="lesson.id"
            :editable="canEdit"
            :withTests="true"
            @updateLesson="updateLesson"
          />
        </SplitArea>
        <SplitArea :size="40">
          <vs-tabs>
            <vs-tab label="Prev lesson">
              <PrevLessonView />
            </vs-tab>
          </vs-tabs>
        </SplitArea>
      </Split>
    </div>
    <vs-popup
      class="form-popup lesson__popup"
      classContent="lesson__popup-content"
      title="Lesson"
      :active.sync="popupActive"
      v-if="popupActive"
    >
      <LessonForm
        v-if="popupActive"
        :lesson="lesson"
        @closePopup="closePopup"
      />
    </vs-popup>
    <div class="popup-edit" v-if="surprisePopupActive">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        title="Add surprise"
        :active.sync="surprisePopupActive"
        v-if="surprisePopupActive"
      >
        <surprise-form
          :students="lesson.students"
          :lesson="lesson"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LessonView from '@/components/lessons/lessonView.vue'
import LessonForm from '@/components/lessons/lessonForm.vue'
import LessonManage from '@/components/lessons/components/lessonManage.vue'
import PrevLessonView from '@/components/lessons/prevLessonView.vue'
import SurpriseForm from '@/components/students/surprisers/surpriseForm.vue'
import GroupCurrentBooksView from '@/components/books/groups/currentBooks/groupCurrentBooksView.vue'
import LessonOnline from '@/components/common/elements/lessonOnline.vue'

export default {
  name: 'LessonPage',
  title: 'SE-Lesson',
  data() {
    return {
      lessonsHead: [],
      students: [],
      proxy: process.env.VUE_APP_PROXY,
      popupActive: false,
      surprisePopupActive: false,
      reasonToGetInWork: '',
      cancelPromptActive: false,
      cancelDescription: '',
    }
  },
  components: {
    LessonView,
    PrevLessonView,
    LessonForm,
    SurpriseForm,
    LessonManage,
    GroupCurrentBooksView,
    LessonOnline,
  },
  computed: {
    ...mapState({
      group: state => state.groups.singleGroup,
      lessons: state => state.lessons.lessons,
      lesson: state => state.lessons.lesson,
      prevLesson: state => state.lessons.prevLesson,
      tests: state => state.books.tests,
    }),
    started: function() {
      return this.lesson.date_start && !this.lesson.date_end
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem('user'))
    },
    selfLessonTeacher: function() {
      const { main_teacher, other_teachers, assistants } = this.lesson
      const { id } = this.user
      if (typeof main_teacher == 'undefined') return {}
      if (main_teacher.id == id) {
        return { ...main_teacher, type: 'main' }
      }
      // if (other_teachers.hasOwnProperty(id)) {
      if (other_teachers && id in other_teachers) {
        return { ...other_teachers[id], type: 'other' }
      }
      // if (assistants.hasOwnProperty(id)) {
      if (assistants && id in assistants) {
        assistants[id].type = 'assistant'
        return { ...assistants[id], type: 'assistant' }
      }
      return {}
    },
    canEdit: function() {
      const { selfLessonTeacher } = this
      // смотрим, если простой педагог
      if (
        selfLessonTeacher.id &&
        selfLessonTeacher.type != 'assistant' &&
        selfLessonTeacher.date_start &&
        !selfLessonTeacher.date_end
      )
        return true
      // смотрим, если админ
      if (
        this.user.options.roles.includes('admin') &&
        this.lesson.date_start &&
        !this.lesson.date_end
      )
        return true

      return false
    },
  },
  created: async function() {
    const { lesson_id } = this.$route.params
    // let prevLesson = null;

    this.$store.dispatch('lessons/clearPrevLessonAction')

    await this.$store.dispatch('lessons/getSingleLessonAction', {
      id: lesson_id,
    })
    document.title = this.lesson.name
    if (
      this.lesson.lesson_type == 'group' ||
      this.lesson.lesson_type == 'tutor'
    ) {
      await this.$store.dispatch('lessons/getPrevLessonAction', {
        id: lesson_id,
      })
    }

    if (this.lesson.lesson_type == 'group') {
      await this.$store.dispatch('books/getTestsAction', {
        group_id: this.lesson.group.id,
      })

      const lessonTests = [
        ...(this.lesson.options?.tests || []),
        ...(this.prevLesson.options?.tests || []),
      ]
      if (lessonTests.length) {
        await this.$store.dispatch('results/getResultsByLessonAction', {
          lesson_id,
          test_id: [...lessonTests],
        })
      }
    }
    if (this.lesson.lesson_type == 'testing') {
      await this.$store.dispatch(
        'results/getIncomeTestsResultsByLessonAction',
        {
          lesson_id,
        }
      )
    }
    // запрашиваем заметки по всем занятиям сразу
    let lesson_ids = [lesson_id]
    if (this.prevLesson.id) {
      lesson_ids.push(this.prevLesson.id)
    }
    await this.$store.dispatch('teachers/getNotesAction', { lesson_ids })
  },
  methods: {
    getLessonHead: function() {
      return [this.lesson]
    },
    tempCheckTeacher: function() {
      console.log(
        this.selfLessonTeacher.id &&
          this.selfLessonTeacher.date_start &&
          !this.selfLessonTeacher.date_end
      )
    },
    getLessonStudents: function() {
      // const group = this.lesson.group;
      let students = this.lesson.students.length
        ? this.lesson.students
        : this.group.students
      students = students.map(student => {
        return {
          lessons: [
            {
              cancelled: this.lesson.cancelled,
              exist: student.exist || '',
            },
          ],
          name: student.name || '',
          id: student.id,
          student_class: student.student_class,
          link_id: student.link_id || null,
          comment: student.comment || '',
          lesson_id: this.lesson.id,
          // TODO: books, tests
        }
      })
      students = students.sort(function(a, b) {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
      return students
    },

    closePopup: function() {
      this.popupActive = false
      this.surprisePopupActive = false
    },
    removeAllTestHandler: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text:
          'Удалить все тесты из занятия? Удалятся также все результаты тестов.',
        accept: this.removeTests,
      })
      return
    },
    removeTests: function() {
      this.$store
        .dispatch('results/deleteResultsFromLessonAction', {
          lesson_id: this.lesson.id,
        })
        .then(() => window.location.reload())
    },
    addTestHandle: function(e, test_id) {
      e.preventDefault()
      this.test_id = test_id
      if (this.lesson.options && this.lesson.options.tests) {
        if (this.lesson.options.tests.includes(test_id.toString())) {
          this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Confirm`,
            text:
              'Тест уже привязан к данному занятию. Сделать еще одну связь?',
            accept: this.addTest,
          })
          return
        }
      }
      this.addTest()
    },
    addTest: function() {
      const { test_id } = this
      const { lesson_id } = this.$route.params
      this.$store
        .dispatch('results/setTestToLessonAction', {
          lesson_id: this.lesson.id,
          group_id: this.lesson.group.id,
          test_id,
        })
        .then(() => {
          this.$store.dispatch('lessons/getSingleLessonAction', {
            id: lesson_id,
          })
          // window.location.reload();
        })
    },
    updateLesson: function() {
      const { lesson_id } = this.$route.params
      this.$store.dispatch('lessons/getSingleLessonAction', {
        id: lesson_id,
      })
    },
    addAllIncomeTestsHandler: async function() {
      const { id, students } = this.lesson
      let data = students.map(s => {
        return {
          lesson_id: id,
          student_id: s.id,
        }
      })

      await this.$store
        .dispatch('results/addIncomeTestResultAction', { data })
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: `Tests added`,
            color: 'success',
            position: 'top-right',
          })
        })
      await this.$store.dispatch(
        'results/getIncomeTestsResultsByLessonAction',
        {
          lesson_id: id,
        }
      )
    },
    deleteLessonHandler: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Удалить занятие? Данные удалятся безвозвратно!',
        accept: () => {
          const { id } = this.lesson
          this.$store.dispatch('lessons/deteleLessonAction', id)
        },
      })
      return
    },
  },
  // watch: {
  //   lesson: {
  //     deep: true,
  //     handler: function() {
  //       this.students = this.getLessonStudents();
  //       this.lessonsHead = this.getLessonHead();
  //     }
  //   }
  // }
}
</script>

<style lang="scss">
.status-bar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  align-content: center;
  h2 {
    margin: 0 0 0 5px;
    text-align: left;
  }
  &__manage-buttons {
    margin-left: 10px;
    button {
      margin-right: 10px;
    }
  }
  &__lesson-time {
    margin: 0 10px;
    font-size: 0.9em;
  }
  &__room,
  &__teacher {
    margin: 0 10px;
  }
  &__lesson-group,
  &__lesson-testing,
  &__teacher {
    vertical-align: middle;
    margin-left: 15px;
  }
  &__lesson-testing {
    .con-vs-chip {
      background-color: var(--testing-bcg-color);
    }
  }
  .con-vs-chip {
    float: none !important;
  }
  .cancel-desription {
    color: var(--font-negative-color);
    font-weight: bold;
  }
}
.lesson__sub-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 7px;
  gap: 5px;
  padding-right: 15px;
  .surprise__add-button {
    flex: 1;
  }
}
.lesson__popup .vs-popup {
  width: 60%;
}
.crossed {
  text-decoration: line-through;
}
.lesson {
  .con-vs-chip {
    font-weight: 700;
    box-shadow: 0 1px 3px 1px #ccc;
    font-size: 0.9em;
  }
}
.lesson-tests {
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 7px;
  cursor: pointer;
  .add-test-dropdown {
    margin-right: 25px;
  }
  &:hover {
    background-color: #eee;
  }
}
.test-completed {
  width: 25px;
  display: inline-block;
  vertical-align: middle;
}
.lesson-in-work {
  color: var(--font-primary-color);
  font-weight: bold;
  padding: 0 7px;
  font-size: 0.8em;
}
.lesson-in-done {
  color: var(--font-positive-color);
  font-weight: bold;
  padding: 0 7px;
  font-size: 0.8em;
}
.lesson__popup-content {
  overflow: visible;
}
.con-vs-alert {
  height: auto !important;
}
.col-income-result-name,
.col-income-result-oral-value,
.col-income-result-written-value,
.col-result-sended {
  & > div {
    & > div {
      display: flex;
      flex-flow: column nowrap;
      & > div {
        flex: 1;
        textarea {
          font-size: 0.7rem;
        }
      }
    }
  }
}
.add-teacher-note-button {
  .vs-button {
    float: none;
  }
}
</style>
